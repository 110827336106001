import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { useTranslation as I18nUseTranslation } from 'react-i18next'
import en_us from './resources_en_us'
import es_cl from './resources_es_cl'

export const languages = {
  ES_CL: 'es_cl',
  EN_US: 'en_us',
}

export const resources = {
  en_us,
  es_cl,
}

i18n.use(initReactI18next).init({
  ns: ['common'],
  defaultNS: 'common',
  compatibilityJSON: 'v3',
  resources,
  lng: languages.EN_US,
  fallbackLng: languages.EN_US,
  debug: false,
  //process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test',
  interpolation: {
    escapeValue: false,
  },
})

export const useLocalization = () => {
  return {
    changeLanguage: (lang: string) => {
      i18n.changeLanguage(lang)
    },
    currentLanguage: () => i18n.language,
    currentLanguageBackendFormat: () => {
      const defaultLanguage = 'en_us'
      const lang = i18n.language
      const transformLanf: Record<
        string,
        'en_us' | 'en_gb' | 'es_us' | 'es_cl' | 'es_mx'
      > = {
        'en-us': 'en_us',
        'es-cl': 'es_cl',
      }

      const currentLanguage = transformLanf[lang] ?? defaultLanguage

      return currentLanguage
    },
  }
}

export const I18N = i18n
export const useTypedTranslation = I18nUseTranslation
