import accountSettings from './en-us/accountSettings.json';
import common from './en-us/common.json';
import faq from './en-us/faq.json';
import footer from './en-us/footer.json';
import forms from './en-us/forms.json';
import menu from './en-us/menu.json';
import onboarding from './en-us/onboarding.json';
import organizationWizard from './en-us/organizationWizard.json';
import productSettings from './en-us/productSettings.json';
import report from './en-us/report.json';
import search from './en-us/search.json';
import terms from './en-us/terms.json';

const resources = {
  accountSettings,
  common,
  faq,
  footer,
  forms,
  menu,
  onboarding,
  organizationWizard,
  productSettings,
  report,
  search,
  terms
} as const;

export default resources;
